<template>
  <div style="width: 0px; height: 1000px">
    <VueDeckGL
      style="z-index: 1"
      :layers="layers"
      :viewState="viewState"
      :viewStateLimits="viewStateLimits"
      :views="views"
      :effects="effects"
      :tooltip="tooltip"
      @click="handleClick"
      @view-state-change="handleViewStateChange"
    >
      <template v-slot:foreground>
        <div
          style="
            position: absolute;
            left: 20px;
            top: 20px;
            z-index: 2;
            margin: 10px;
            padding: 20px;
            background-color: rgba(25, 25, 25, 0.8);
            display: flex;
            flex-direction: column;
            justify-content: start;
          "
        >
          <input
            type="checkbox"
            name="showHeightData"
            checked
            v-model="showHeightData"
          />
          <label style="color: white" for="showHeightData">3D?</label>
          <select v-model="heightmapVersion" name="heightmapVersion">
            <option disabled value="">Select heightmap version</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>1_rgb</option>
            <option>2_rgb</option>
            <option>3_rgb</option>
            <option>4_rgb</option>
          </select>
          <input
            type="range"
            min="0"
            max="10"
            step="0.1"
            v-model="heightScaling"
            name="heightScaling"
          />
          <label style="color: white" for="heightScaling">
            Height scaling: {{ heightScaling }}
          </label>
          <input
            type="range"
            min="0"
            max="50"
            step="0.1"
            v-model="elevationScaling"
            name="elevationScaling"
          />
          <label style="color: white" for="elevationScaling">
            NPC elevation scaling {{ elevationScaling }}
          </label>
          <input v-model="heightOffset" name="heightOffset" />
          <label style="color: white" for="heightOffset">
            Height Offset {{ heightOffset }}
          </label>
          <input v-model="modelMatrixScaling" name="modelMatrixScaling" />
          <label style="color: white" for="modelMatrixScaling">
            Model Matrix Scaling {{ modelMatrixScaling }}
          </label>
        </div>
      </template>
    </VueDeckGL>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import VueDeckGL from "@/components/VueDeckGL.vue";

import NodeSupabaseResponse from "@/assets/data/nodeSupabaseResponse.json";

import { TerrainLayer } from "@deck.gl/geo-layers";
import { GeoJsonLayer, IconLayer, ArcLayer, LineLayer } from "@deck.gl/layers";
import { BitmapLayer } from "@deck.gl/layers";
import { TileLayer } from "@deck.gl/geo-layers";
import { COORDINATE_SYSTEM } from "@deck.gl/core";
import { MapView } from "@deck.gl/core";
import { AmbientLight, LightingEffect } from "@deck.gl/core";

export default {
  name: "Map",
  components: {
    VueDeckGL,
  },
  data() {
    return {
      elevationScaling: 11,
      heightScaling: 1,
      heightmapVersion: "4_rgb",
      showHeightData: true,
      heightOffset: -426000,
      modelMatrixScaling: 0.0001565,
      gatherablesFilter: 10065,
      viewState: {
        minZoom: 1.5,
        maxZoom: 7.5,
        longitude: 30,
        latitude: -50,
        zoom: 6,
      },
    };
  },
  methods: {
    handleClick({ event, info }) {
      // handle clicks on the deck instance
      // console.log(event, info);
    },
    handleViewStateChange(updatedViewState) {
      // update the state object
      this.viewState = {
        ...updatedViewState,
      };
    },
  },
  watch: {
    showHeightData(value) {
      if (value) {
        this.$set(this.viewState, "bearing", 0);
        this.$set(this.viewState, "pitch", 20);
      } else {
        this.$set(this.viewState, "bearing", 0);
        this.$set(this.viewState, "pitch", 0);
      }
    },
  },
  computed: {
    // filteredGatherables() {
    //   if (!this.gatherablesFilter) return Gatherables;
    //   return Gatherables.features.filter((gatherable) => {
    //     return gatherable.properties.id == this.gatherablesFilter;
    //   });
    // },
    viewStateLimits() {
      if (this.showHeightData) {
        return {
          minBearing: -180,
          maxBearing: 180,
          minPitch: 0,
          maxPitch: 60,
        };
      }
      return {
        minBearing: 0,
        maxBearing: 0,
        minPitch: 0,
        maxPitch: 0,
      };
    },
    nodeLayerData() {
      var data = JSON.parse(JSON.stringify(NodeSupabaseResponse));
      let nodes = [];
      for (const node of data) {
        // Manipulating the height here since the watcher in VueDeckGL cant be set to deep mode and otherwise wont react to changes
        // Using local variables from this component in functions that get passed over wont work
        if (!node.coordinates || !node.is_main_node) {
          continue;
        }
        node.coordinates = [
          node.coordinates[0],
          node.coordinates[2],
          node.coordinates[1],
        ];
        if (!this.showHeightData) {
          node.coordinates[2] = 1000;
        }
        nodes.push(node);
      }
      return nodes;
    },
    arcLayerData() {
      let links = [];
      console.log(this.nodeLayerData.length);
      const data = this.nodeLayerData;
      for (const link of data) {
        for (const targetId of link.connections) {
          let target = data.find((node) => node.waypoint_id == targetId);
          if (!target) continue;
          links.push({
            to: target.coordinates,
            from: link.coordinates,
            color:
              (link.need_explore_point === 0 && link.node_kind === 0) ||
              (target.need_explore_point === 0 && target.node_kind === 0)
                ? [0, 140, 200]
                : [200, 140, 0],
          });
        }
      }
      return links;
    },
    tooltip() {
      return ({ object }) => object && `${object.Name}`;
    },
    tileLayer() {
      return new TileLayer({
        // coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        // wireframe: true,
        tileSize: 200,
        visible: !this.showHeightData,
        // visible: this.viewState.zoom < 6,
        data: "https://cdn.bdolytics.com/map/dev/maptilesv2/{z}/{x}/{y}.webp",
        minZoom: 0,
        maxZoom: 7,
        // coordinateOrigin: [20000, 20000, 0],

        renderSubLayers: (props) => {
          const {
            bbox: { west, south, east, north },
          } = props.tile;

          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            bounds: [west, south, east, north],
          });
        },
      });
    },
    terrainLayer() {
      return new TerrainLayer({
        elevationDecoder: {
          rScaler: this.heightScaling * 0.1,
          gScaler: this.heightScaling * 25.6,
          bScaler: this.heightScaling * 6553.6,
          offset: this.heightOffset,
        },
        visible: this.showHeightData,
        // visible: this.viewState.zoom > 4,
        wireframe: false,
        meshMaxError: 256,
        tileSize: 256,
        material: false,
        loadOptions: {
          terrain: {
            skirtHeight: 40000,
          },
        },

        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,

        minZoom: 0,
        maxZoom: 7,
        elevationData: `https://cdn.bdolytics.com/map/dev/heightmapv${this.heightmapVersion}/{z}/{x}/{y}.png`,
        texture:
          "https://cdn.bdolytics.com/map/dev/maptilesv2/{z}/{x}/{y}.webp",
      });
    },
    nodeLayer() {
      return new IconLayer({
        id: "icon-layer",
        data: this.nodeLayerData,
        pickable: true,
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        coordinateOrigin: [297.34, 176.266, 0],
        modelMatrix: [
          this.modelMatrixScaling,
          0,
          0,
          0,
          0,
          this.modelMatrixScaling,
          0,
          0,
          0,
          0,
          this.showHeightData ? this.elevationScaling : 1,
          0,
          0,
          0,
          0,
          1,
        ],
        loadOptions: {
          imagebitmap: {
            resizeWidth: 256,
            resizeHeight: 256,
          },
        },
        // iconAtlas and iconMapping are required
        // getIcon: return a string
        getIcon: (d) => ({
          url: `https://cdn.bdolytics.com/map/icons/node_icons/node_${d.node_kind}.svg`,
          width: 256,
          height: 256,
          anchorY: 256,
        }),
        getPosition: (d) => d.coordinates,
        sizeUnits: "pixels",
        sizeScale: this.zoomScaler,
        // onClick: (info, event) => console.log("Clicked:", info, event),
      });
    },
    zoomScaler() {
      return Math.log(this.viewState.zoom, 1 / 2) * 40;
    },
    arcLayer() {
      return new ArcLayer({
        id: "arc-layer",
        data: this.arcLayerData,
        visible: this.showHeightData,
        pickable: true,
        getWidth: 4,
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        coordinateOrigin: [297.34, 176.266, 0],
        opacity: 0.4,
        modelMatrix: [
          this.modelMatrixScaling,
          0,
          0,
          0,
          0,
          this.modelMatrixScaling,
          0,
          0,
          0,
          0,
          this.showHeightData ? this.elevationScaling : 0,
          0,
          0,
          0,
          0,
          1,
        ],
        getSourcePosition: (d) => d.from,
        getTargetPosition: (d) => d.to,
        getSourceColor: (d) => d.color,
        getTargetColor: (d) => d.color,
      });
    },
    lineLayer() {
      return new LineLayer({
        id: "line-layer",
        data: this.arcLayerData,
        visible: !this.showHeightData,
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        coordinateOrigin: [297.34, 176.266, 0],
        modelMatrix: [
          this.modelMatrixScaling,
          0,
          0,
          0,
          0,
          this.modelMatrixScaling,
          0,
          0,
          0,
          0,
          this.showHeightData ? this.elevationScaling : 0,
          0,
          0,
          0,
          0,
          1,
        ],
        pickable: true,
        opacity: 0.4,
        getWidth: 4,
        getSourcePosition: (d) => [d.from[0], d.from[1], 1],
        getTargetPosition: (d) => [d.to[0], d.to[1], 1],
        getColor: (d) => d.color,
      });
    },
    layers() {
      // const monsterPositions = new GeoJsonLayer({
      //   id: "MonsterPositions",
      //   data: Monsters,

      //   // elevationScale: 1,
      //   extruded: true,
      //   filled: true,
      //   // getElevation: 30,
      //   getFillColor: (m) => m.properties.color,
      //   getPointRadius: 4,
      //   lineWidthMinPixels: 1,
      //   pointRadiusUnits: "pixels",

      //   coordinateOrigin: [297.34, 176.266, 0],
      //   coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      //   modelMatrix: [
      //     this.modelMatrixScaling,
      //     0,
      //     0,
      //     0,
      //     0,
      //     this.modelMatrixScaling,
      //     0,
      //     0,
      //     0,
      //     0,
      //     this.elevationScaling /*0.000147*/,
      //     0,
      //     0,
      //     0,
      //     0,
      //     1,
      //   ],
      // });

      // const gatherablesLayer = new GeoJsonLayer({
      //   id: "Gatherables",
      //   data: this.filteredGatherables,

      //   // elevationScale: 1,
      //   extruded: true,
      //   filled: true,
      //   // getElevation: 30,
      //   getPointRadius: 4,
      //   lineWidthMinPixels: 1,
      //   pointRadiusUnits: "pixels",

      //   coordinateOrigin: [297.34, 176.266, 0],
      //   coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      //   modelMatrix: [
      //     this.modelMatrixScaling,
      //     0,
      //     0,
      //     0,
      //     0,
      //     this.modelMatrixScaling,
      //     0,
      //     0,
      //     0,
      //     0,
      //     this.elevationScaling /*0.000147*/,
      //     0,
      //     0,
      //     0,
      //     0,
      //     1,
      //   ],
      // });

      // const nodeData = new GeoJsonLayer({
      //   id: "NodeData",
      //   data: Nodes,

      //   // elevationScale: 1,
      //   extruded: true,
      //   filled: true,
      //   // getElevation: 30,
      //   getFillColor: [120, 0, 200, 255],
      //   getPointRadius: 6,
      //   pointRadiusUnits: "pixels",

      //   coordinateOrigin: [297.34, 176.266, 0],
      //   coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
      //   modelMatrix: [
      //     this.modelMatrixScaling,
      //     0,
      //     0,
      //     0,
      //     0,
      //     this.modelMatrixScaling,
      //     0,
      //     0,
      //     0,
      //     0,
      //     this.elevationScaling /*0.000147*/,
      //     0,
      //     0,
      //     0,
      //     0,
      //     1,
      //   ],
      // });
      return [
        this.terrainLayer,
        this.tileLayer,
        this.arcLayer,
        this.lineLayer,
        this.nodeLayer,
      ];
    },
    effects() {
      const ambientLight = new AmbientLight({
        color: [255, 255, 255],
        intensity: 3,
      });

      return [
        new LightingEffect({
          ambientLight,
        }),
      ];
    },
    views() {
      return new MapView({
        repeat: false,
        nearZMultiplier: 0.01,
        farZMultiplier: 1.5,
      });
    },
  },
};
</script>
